import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { toCapitalizeFirst } from '@/services/utils/global.js'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfPrinter = () => { 

    const {formatCurrency, currencyToWord} = handlePurchase();
    const exportToPDF = (company, invoice, barcode, qrcode, userName, btnFunc, invoiceNotes = []) => {
        const tableItems = invoice.has_item_detail ? invoice.general : invoice.ledgers;
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(invoice, tableItems, barcode, btnFunc, invoiceNotes),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10
            },
            info: {
                title: invoice.bill_number
            }
        }

        pushIntoTable(doc, invoice, tableItems, btnFunc)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }
    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }
    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
    const getHeader = (company) => {
        return {
            margin: [ 60, 25, 60, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60,
                    maxWidth: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email,
                        'VAT Reg No: ' + company.vat_no,
                    ]
                }
            ]
        }
    }
    const getContent = (invoice, tableItems, barcode, btnFunc, invoiceNotes) => {
        return  [
            {
                text: (btnFunc == 1) ? 'INVOICE' : 'DELIVERY CHALLAN',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                text: (btnFunc == 1) ? 'INVOICE TO' : 'SHIP TO',
                                color: 'black',
                                bold: true,

                            },

                            {
                                text: invoice.contact.name,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: getProfileItemByField(invoice.contact.profile_items, 'address'),
                            },

                            {
                                text: `${invoice.contact.address ? invoice.contact.address : ''}`
                            }
                        ]

                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                text: [
                                    {
                                        text: (btnFunc == 1) ? 'DATE: ' : 'CHALLAN DATE: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: formatToDdMmYy(invoice.date),
                                    }
                                 ]
                            },

                            {
                                text: [
                                    {
                                        text: (btnFunc == 1) ? 'INVOICE REF: ' : 'CHALLAN NO: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: invoice.bill_number
                                    }
                                 ]
                            },

                            {
                                svg: barcode
                            },

                            (btnFunc == 1) ? '' : {
                                stack: [
                                    {
                                        text: invoice.location_id ? 'SHIP FROM:' : '',
                                        bold: true
                                    },

                                    {
                                        text: invoice.location_id
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: (btnFunc ==1) ? ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : ['*', 'auto', 'auto', 'auto'],
                    body: []
                },
                layout: {
			        fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
		        }
		    },

            {
                columns: [
                    {
                        width: '65%',
                        text: [
                            {
                                text: 'In Words: ',
                                bold: true
                            },

                            {
                                text: invoice.total_amount ? currencyToWord(invoice.total_amount) : ''
                            }
                        ]
                    },
                     
                    (btnFunc == 1) ? {
                        table: {
                            widths: ['*', '*'],
                            alignment: 'right',
                            body: formatTotals(invoice)
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        }
                    } : ''
                ],
                margin: [0, 10]
            },

            invoiceNotes.length ? {
                alignment: 'left',
                stack: formatInvoiceTerms(invoiceNotes)
            } : '',

            {
                alignment: 'justify',
                text: [{ text: invoice.description ? 'NOTE: \n' : '', bold: true}, {text: invoice.description} ]
            }

        ]
    }
    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 60, 0, 60, 0 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    const getProfileItemByField = (profile_items, keyValue) => {
        const item = profile_items.find(item => item.field === keyValue);
        return (item) ? item.value : '';
    }

    const formatTotals = (invoice) => {
        const totals = [
            [{ text: 'Sales Amount' }, { text: formatCurrency(invoice.subtotal_amount), alignment: 'right'}],
            [ { text: 'VAT AMOUNT' }, {text:  formatCurrency(invoice.vat_amount), alignment: 'right' } ],
            [ { text: 'Total Invoice Amount', bold: true }, { text: formatCurrency(invoice.total_amount), alignment: 'right'}],
        ]

        if(! invoice.paid_amount) return totals;

        return totals.concat([
            [ { text: 'Amount Paid', bold: true }, { text: formatCurrency(invoice.paid_amount), alignment: 'right'}],
            [ { text: 'Total Payable', bold: true }, { text: formatCurrency(invoice.total_amount - invoice.paid_amount), alignment: 'right'}],
        ]);
    }

    const formatInvoiceTerms = (terms) => {
        return  terms.map((item, index) => {
            return {
                text: `${item.full_desc}`
            }
        })
    }

    const pushIntoTable = (doc, invoice, tableItems, btnFunc) => {
        var flag = false;
        tableItems.forEach(item => {
            if( !flag ){
                const tableHeader = getTableHeader(btnFunc);
                doc.content[2].table.body.push(tableHeader);
                flag = true;
            }
            const rowData = getRowData(invoice, item, btnFunc);
            return doc.content[2].table.body.push(rowData);
        });
    }
    const getTableHeader = (btnFunc) => {
        let headerInvoice = [
            { text: 'PARTICULARS' },
            { text: 'QTY' },
            { text: 'RATE' },
            { text: 'AMOUNT', alignment: 'right' },
            { text: 'VAT RATE', alignment: 'right' },
            { text: 'VAT AMOUNT', alignment: 'right' },
            { text: 'TOTAL AMOUNT', alignment: 'right' }
        ]

        let headerChallan = [
            { text: 'PARTICULARS' },
            { text: 'UNIT', alignment: 'left' },
            { text: 'DELIVERY QTY', alignment: 'right' },
            { text: 'REMARKS' }
        ]
        
        return (btnFunc == 1) ? headerInvoice : headerChallan;
    }
    const getRowData = (invoice, item, btnFunc) => {
        let finaTotalAmount = item.total_amount
        if (!invoice.has_item_detail){
            finaTotalAmount = item.credit - item.discount_amount + item.vat_amount
        }

        return (btnFunc == 1) ? 
            [ { text: (invoice.has_item_detail ? item.product.name : item.head.name) + '\n' + (item.description ? item.description : '') + '\n' + (item.sale_order_type ? toCapitalizeFirst(item.sale_order_type) : '') },
            { text:  invoice.has_item_detail ? formatCurrency(item.quantity) : '', alignment: 'right' },
            { text: invoice.has_item_detail ? formatCurrency(item.rate) : '', alignment: 'right' },
            { text: formatCurrency(item.amount), alignment: 'right' },
            { text: formatCurrency(item.vat), alignment: 'right' },
            { text: formatCurrency(item.vat_amount), alignment: 'right' },
            { text: formatCurrency(item.total_amount), alignment: 'right' } ] : 
            
            [ { text: (invoice.has_item_detail ? item.product.name : item.head.name) + '\n' + (item.description ? item.description : '') },
            { text: item.product ? item.product.description.unit.name : '' },
            { text: formatCurrency(invoice.total_quantity), alignment: 'right' },
            { text: '' } ]
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
