<template>
  <div id="users-list" class="chat-user-list-wrapper list-group">
    <ul class="chat-users-list contact-list media-list">
      <template v-if="contacts.length">
        <li 
          v-for="contact in contacts" 
          :class="{ 'active': contact.id == selectedContactId }"
          @click="$emit('selectContact', contact.id)"
          :key="contact.id"
        >
        <span class="avatar">
          <img :src="contact.photo"  alt="Generic placeholder image" class="border-0"/>
        </span>
          <div class="chat-info">
            <h5 class="mb-0">{{ contact.full_name }} ({{ contact.serial_no }})</h5>
            <p class="card-text text-truncate m-0">
              {{ contact.mobile_no }}
            </p>
          </div>
        </li>
      </template>
      <li v-else>
        <h6 class="mb-0 text-center">No data found</h6>
      </li>
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </ul>
  </div>
</template>

<script setup>
import Pagination from '@/components/atom/Pagination.vue'

const emit = defineEmits(['onPageChange'])
const props = defineProps({
  contacts: {
    type: Array,
    default: []
  },
  selectedContactId: {
    default: '',
  },
  offset: {
    type: String
  }
})

const onPageChange = page => {
  emit('onPageChange', page)
};
</script>

<style lang="scss">
.chat-application {
  .sidebar-content {
    .chat-user-list-wrapper {
      width: 300px;
      overflow-y: auto;
      overflow-x: hidden;

      ul.pagination {
        margin-top: 10px;
      }

      li.page-item {
        padding: 0 !important;
      }
    }
  }
}
</style>
